<template>
  <section class="add-section panel-section">
    <div class="back-btn" @click="back">
      <i class="el-icon-arrow-left"></i>
      <span>返回</span>
    </div>
    <div class="title">{{comTitle}}燃料兑换券</div>
    <el-form
      :model="formModel"
      label-position="left"
      status-icon
      :rules="rules"
      ref="formModel"
      label-width="130px"
      class="demo-formModel"
    >
      <el-form-item label="兑换券码" prop="voucherCode">
        <el-input
          v-model="formModel.voucherCode"
          disabled
          placeholder="系统自动生成"
        ></el-input>
      </el-form-item>
      <el-form-item label="指定渠道">
        <el-select v-model="formModel.shopId" clearable placeholder="请选择渠道">
          <el-option
            v-for="(item, i) in shopIdList"
            :key="i"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="兑换券燃料面额" prop="amount">
        <el-input
          v-model="formModel.amount"
          @input="changeInput"
          placeholder="请输入燃料面额"
          maxLength="6"
          :disabled="Boolean(id)"
        ></el-input>
      </el-form-item>
      <el-form-item label="有效期" prop="expiredTime">
        <el-select
          v-model="formModel.expiredTime"
          disabled
          placeholder="请选择有效期"
        >
          <el-option label="不限" value="999"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submitForm('formModel')"
          >{{comTitle}}</el-button
        >
        <el-button @click="resetForm('formModel')">重置</el-button>
      </el-form-item>
    </el-form>
  </section>
</template>
<script>
//客户管理--新增客户
import API_TOOL from "@/api/api_tool";
import API_ACCOUNT from "@/api/api_account";
import { mapGetters, mapMutations } from 'vuex';

export default {
  components: {},
  data() {
    return {
      formModel: {
        voucherCode: undefined,
        shopId: undefined,
        amount: undefined,
        expiredTime: "999",
      },
      rules: {
        shopId: { required: true, message: "请选择渠道" },
        amount: { required: true, message: "请输入燃料面额" },
        expiredTime: { required: true, message: "" },
      },
      shopIdList: [],
      id: ''
    };
  },
  computed: {
    ...mapGetters(["voucherDesc"]),
    comTitle() {
      return this.id ? '更新' : '新增';
    }
  },
  methods: {
    ...mapMutations(["setVoucherDesc"]),
    back() {
      this.$router.go(-1);
    },
    resetForm(name) {
      if(this.id) {
        this.setDetail();
      }else {
        this.$refs[name].resetFields();
      }
    },
    getShopIdList() {
      API_ACCOUNT.getAccountFill({
        pageNo: 1,
        pageSize: 200,
        auditStatus: 2
      }).then((res = {}) => {
        const {
          data: { data: { records = [], total } = {}, code, msg = "" } = {},
        } = res;
        if (code == 200 && records.length > 0) {
          this.shopIdList = records;
        } else {
          this.shopIdList = [];
        }
      });
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$confirm(`确定${this.comTitle}燃料兑换券吗？`, "提示", {
            type: "warning",
          }).then(() => {
            if(this.id) {
              API_TOOL.editVoucher({
                id: this.id,
                voucherCode: this.formModel.voucherCode,
                shopId: this.formModel.shopId,
                amount: this.formModel.amount
              }).then(({ data }) => {
                if (data.code == 200) {
                  this.$message({
                    message: "更新成功~",
                    type: "success",
                  });
                  this.back();
                } else {
                  this.$message.error({
                    message: data.msg || this.$CommonMsg["networkErr"],
                  });
                }
              });
            }else {
              API_TOOL.addVoucher({
                shopId: this.formModel.shopId,
                amount: this.formModel.amount
              }).then(({ data }) => {
                if (data.code == 200) {
                  this.$message({
                    message: "新增成功~",
                    type: "success",
                  });
                  this.back();
                } else {
                  this.$message.error({
                    message: data.msg || this.$CommonMsg["networkErr"],
                  });
                }
              });
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    changeInput() {
      const pattern = /^[1-9][0-9]*$/;
      if (!pattern.test(this.formModel.amount)) {
        this.formModel.amount = '';
      }
    },
    setDetail() {
      this.formModel.voucherCode = this.voucherDesc.voucherCode;
      this.formModel.shopId = this.voucherDesc.shopId;
      this.formModel.amount = this.voucherDesc.amount;
    },
  },
  created() {
    this.id = this.$route.query.id;
    if (!this.voucherDesc.id) {
      this.setVoucherDesc(
        JSON.parse(sessionStorage.getItem("voucherDesc") || "{}")
      );
    }
  },
  activated() {
    this.id = this.$route.query.id;
    this.getShopIdList();
    if(this.id) {
      this.setDetail();
    }
  },
  deactivated() {
    this.formModel = {
      voucherCode: undefined,
      shopId: undefined,
      amount: undefined,
      expiredTime: "999",
    };
    this.id = undefined;
    this.setVoucherDesc({});
    this.resetForm("formModel");
  },
};
</script>
<style lang="scss" scoped>
@import "../../scss/_basePage.scss";

.add-section {
  padding: 40px 48px;
  .title {
    font-family: "Microsoft YaHei UI";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #303133;
    margin: 24px 0;
  }
}
/deep/ .el-form-item__content {
  width: 390px;
}
</style>
