import * as API from './request';
const comUrl = "/v1/admin/";
export default {	
  voucherRecords(params){
		return API.get(`${comUrl}voucher/page`, params);
	},
  addVoucher(params) {
    return API.post(`${comUrl}voucher`, params);
  },
  editVoucher(params) {
    return API.put(`${comUrl}voucher`, params);
  }
}